<template>
   <el-container class="recordadd p15">
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>留样管理</el-breadcrumb-item>
       <el-breadcrumb-item  to="/admin/sample">留样列表</el-breadcrumb-item>
       <el-breadcrumb-item v-if="formInline.sample_id">编辑留样</el-breadcrumb-item>
       <el-breadcrumb-item v-else>新增留样</el-breadcrumb-item>
    </el-breadcrumb>
        <div>
          
          <el-button  class="back_btn"  size="medium" plain @click="$router.back()">返回</el-button>
        </div>
    </el-header>
    <el-main  class="pt0">
      <el-form  :inline="true" status-icon ref="formInline"  :model="formInline"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
         <el-col :span="24" class="recordadd-title bgcfbe tl mb20 pl15">基础信息</el-col>
        <el-col :span="11" class="tl" >
         <el-form-item class="w" label-width="28%" label="留样时间：" size="medium" prop="sample_time" :rules="[{required: true,message: '请选择留样时间', trigger: 'blur' }]">
          <el-date-picker type="datetime" placeholder="选择留样时间" v-model="formInline.sample_time" format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" default-time="12:00:00" ></el-date-picker>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl">
        <el-form-item class="w" label-width="28%" label="留样食材名称：" size="medium" prop="sample_name" :rules="[{required: true,message: '请输入留样食材名称', trigger: 'blur' }]">
         <el-input placeholder="请输入留样食材名称" v-model="formInline.sample_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="留样数量（g）：" size="medium" prop="sample_num" :rules="[{required: true,message: '请输入留样数量（g）', trigger: 'blur' }]">
           <el-input placeholder="请输入留样数量（g）" v-model="formInline.sample_num" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
           <el-form-item class="w" label-width="28%" label="留样人：" size="medium" prop="sample_person" :rules="[{required: true,message: '请输入留样人', trigger: 'blur' }]">
           <el-input placeholder="请输入留样人" v-model="formInline.sample_person" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
       <el-col :span="22"  class="tl" >
         <el-form-item class="w w24" label-width="14%"   label="留样图片：" size="medium" >
           <div class="recordadd-upload">
              <el-upload 
                ref="upload"
                multiple
                v-if="imageUrl=='1234567'"
                action="/pc/file/upload"
                 accept="image/png,image/jpg,image/jpeg"
                list-type="picture-card"
                :file-list="fileList"
                :on-success="handlepoll"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                 :class="{disabled:uploadDisabled}"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-upload 
                ref="upload"
                multiple
                action=""
                v-else
                 accept="image/png,image/jpg,image/jpeg"
                list-type="picture-card"
                :file-list="fileList"
                :http-request="(params) =>{return handlepoll(params)}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                 :class="{disabled:uploadDisabled}"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              </div>
        </el-form-item>
        </el-col>
         <el-col :span="22" class="tl" :offset="1">
           <el-button  class="" size="medium" type="primary" @click="submitForm('formInline')">提交</el-button>
        </el-col>
     </el-row>
     
 </el-form>
    </el-main>
   </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session} from '../../../util/util'
import {imageUrl} from '../../../config/index'
import { tengxuncloud } from '@/util/aliOss'
let defaultDate;
export default {
  data () {
    return {
        imageUrl:imageUrl,
        options:'',
        user:'',
         uploadDisabled:false,
      uploadDisabled1:false,
      fileList:[],
      formInline: {
          sample_id:'',
        sample_time: this.getNowTime(),
        sample_num: '200',
        sample_name: '',
        sample_person:'',
        accessory_list: [],
      },
      tableData:[],
       dialogImageUrl: '',
        dialogVisible: false
    }
  },
   created () {
       this.user = local.get('user');
          this.formInline.sample_person=this.user.user_name;
       this.formInline.sample_id =session.get('params').id?String(session.get('params').id):'';
    // this.formInline.company_id =local.get('company_id')?String(local.get('company_id')):'';
       if(this.formInline.sample_id){this.init()}
           
  },
  methods: {
       init () {
           this.formInline.accessory_list=[];
           this.fileList=[];
          axios.get("/pc/sample/single",{sample_id:String(this.formInline.sample_id)}).then(v => {
           this.formInline.sample_time=v.data.sample_time;
           this.formInline.sample_num=v.data.sample_num;
           this.formInline.sample_name=v.data.sample_name;
           this.formInline.sample_person=v.data.sample_person;
           v.data.accessory_list.forEach(item=>{
               this.formInline.accessory_list.push({accessory_type:'留样',accessory_url:item.accessory_url})
               this.fileList.push({name:'留样',url:item.accessory_url}) 
           })
          });
       },
       handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
          handlepoll(response, file, fileList) {
             this.uploadDisabled = true;
             if(this.imageUrl=='1234567'){
           this.formInline.accessory_list.push({accessory_type:'留样',accessory_url:response.data.visit_url})
          }else{
           tengxuncloud(response).then((res) => {
             this.formInline.accessory_list.push({accessory_type:'留样',accessory_url:res.url})
           })
          }
             
    },
       handleRemove(file, fileList) {
         this.uploadDisabled = false;
          this.formInline.accessory_list=[];
         fileList.forEach(item1 => {
            this.formInline.accessory_list.push({accessory_type:'留样',accessory_url:item1.url});
        });
        //  this.formInline.dictionary_logo_url=''
      },
         submitForm(formInline){
         this.$refs[formInline].validate((valid) => {
        if (valid) {
            if(this.formInline.sample_id){
                 axios.put('/pc/sample/update', this.formInline).then((v) => {
      this.$router.back()
      })

            }else{
                axios.post('/pc/sample/save', this.formInline).then((v) => {
      this.$router.back()
      })
            }
        
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      
      },
       getNowTime() {
	       var now = new Date();
	       var year = now.getFullYear(); 
	       var month = now.getMonth(); 
         var date = now.getDate();
           var hh =now.getHours() < 10? "0" + now.getHours(): now.getHours();
      var mm =now.getMinutes() < 10? "0" + now.getMinutes(): now.getMinutes();
      var ss =now.getSeconds() < 10? "0" + now.getSeconds():now.getSeconds();
       var hour =' '+hh+':'+mm+':'+ss; 
	       month = month + 1;
	       month = month.toString().padStart(2, "0");
	       date = date.toString().padStart(2, "0");
	       defaultDate = `${year}-${month}-${date}${hour}`;
         return defaultDate;
      },
  }
}
</script>
<style lang="scss" scoped>
.recordadd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
   /deep/ .no-header .has-gutter{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload .el-upload-list__item{width: 160px;height: 160px;float: left;margin-right: 10px;
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}}
      /deep/ .disabled .el-upload--text {
    display: none !important;
}
}

</style>